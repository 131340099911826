import React from 'react';
import { Link } from 'gatsby';
import './Button.scss';

const Button = ({
  onClick,
  buttonText,
  buttonColor = 'black',
  buttonURL,
  buttonAlign = 'left',
}) => {
  if (!onClick && buttonURL) {
    return (
      <Link
        to={buttonURL}
        className={`
          wmfab__button
          align-${buttonAlign}
        `}
      >
        {buttonText}
      </Link>
    );
  }
  return (
    <button
      onClick={onClick ? onClick : null}
      className="wmfab__button"
    >
      {buttonText}
    </button>
  );
};

export default Button;
