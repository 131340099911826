import React from 'react';
import { Grid, Cell } from '@trbl/react-css-grid';
import ContentBlock from '../ContentBlock';
import ShapedImage from '../ShapedImage';
import CTACard from '../CTACard';
import Card from '../Card';

const BTFMachineTemplate = React.memo(({ body, CTA, machines, blockImage }) => {
  return (
    <>
      <section>
        <Grid>
          <Cell
            hStart={8}
            hSpan={11}
            hStartM={9}
            hSpanM={7}
            hStartS={2}
            hSpanS={14}
            id="machine-template--intro"
          >
            {body && (
              <div
                className="wmfab__markup-content"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            )}
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid
          id="machine-template--cta"
        >
          <Cell
            hSpan={16}
            id="machine-template--block-image-divider"
          >
            <ShapedImage
              color="gold"
              topCorner="left"
              bottomCorner="left"
              backgroundImage={blockImage}
            />
          </Cell>
          <Cell
            hStart={8}
            hSpan={9}
            hStartL={7}
            hSpanL={10}
            hStartM={3}
            hSpanM={14}
            hStartS={2}
            hSpanS={14}
            id="machine-template--cta-card-container"
          >
            <CTACard
              title={CTA.title}
              description={CTA.description}
              image={CTA.photo}
              button={CTA.button}
            />
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid
          id="machine-template--machine-list"
        >
          <Cell
            hStart={3}
            hSpan={6}
            hStartL={2}
            hSpanL={6}
            hStartM={2}
            hSpanM={6}
            hStartS={3}
            hSpanS={12}
            id="machine-template--machine-list-copy"
          >
            <ContentBlock
              header="Other Machines"
              body="We work with a variety of equipment to offer full-service metal fabrication solutions."
              headerAlign="center"
              bodyAlign="center"
              short
            />
          </Cell>
          <Cell
            hSpan={6}
            hSpanL={8}
            hSpanM={8}
            hStartS={3}
            hSpanS={12}
          >
            <Grid>
              {machines.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={3}
                    hSpanL={4}
                    hSpanM={4}
                    hSpanS={12}
                    key={title}
                  >
                    <Card
                      name={title}
                      slug={`/machines${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
        </Grid>
      </section>
    </>
  );
});

export default BTFMachineTemplate
