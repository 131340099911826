import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import './Card.scss';

const Card = React.memo(({ name, slug, image = null }) => {
  return (
    <article
      className="wmfab__card"
    >
      <Link to={slug}>
        {image && (
          <Image fluid={image.childImageSharp.fluid} />
        )}
        <h4>{name}</h4>
      </Link>
    </article>
  );
});

export default Card;
