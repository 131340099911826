import React from 'react';
import Image from 'gatsby-image';
import { Grid, Cell } from '@trbl/react-css-grid';
import Button from '../Button';
import './CTACard.scss';

const CTACard = React.memo(({
  title,
  description,
  image,
  button = { text: 'Read More', url: '/'},
}) => {
  const imageSources = [
    image.childImageSharp.mobile,
    {
      ...image.childImageSharp.desktop,
      media: `(min-width: 640px)`,
    },
  ];
  return (
    <article className="wmfab__cta-card">
      <Grid>
        <Cell
          hSpan={3}
          hSpanL={4}
          hSpanS={14}
          className="wmfab__cta-card--image-container"
        >
          <Image fluid={imageSources} />
        </Cell>
        <Cell
          hSpan={6}
          hSpanL={6}
          hSpanM={9}
          hSpanS={14}
          className="wmfab__cta-card--content"
        >
          <h3>
            {title}
          </h3>
          <p>
            {description}
          </p>
          <div className="wmfab__button--container-left">
            <Button buttonText={button.text} buttonURL={button.url} />
          </div>
        </Cell>
      </Grid>
    </article>
  )
});

export default CTACard
