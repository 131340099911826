import React from 'react';
import Button from '../Button';
import './ContentBlock.scss';

const ContentBlock = React.memo(({
  headerAlign = 'left',
  header,
  bodyAlign = 'left',
  body,
  buttonAlign = 'center',
  button = null,
  short,
}) => {
  return (
    <div
      className={`
        wmfab__content-block
        ${short ? 'wmfab__content-block--short' : ''}
      `}
      style={{ textAlign: bodyAlign }}
    >
      {header && (
        <h2
          style={{ textAlign: headerAlign }}
        >
          {header}
        </h2>
      )}
      {body && (
        <p>{body}</p>
      )}
      {button && (
        <div className={`wmfab__button--container-${buttonAlign}`}>
          <Button
            buttonText={button.text}
            buttonURL={button.url}
            onClick={button.onClick}
            align={bodyAlign}
          />
        </div>
      )}
    </div>
  );
});

export default ContentBlock;
